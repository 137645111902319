import React, { useEffect, useState, useContext } from "react";
import CustomPagination from "./components/Pagination";
import DetailModal from "./components/DetailModal";
import AddModal from "./components/AddModal";
import EditModal from "./components/EditModal";
import DeleteModal from "./components/DeleteModal";
import { Button, Container, Table, Card, Row, Col } from "react-bootstrap";
import { AiOutlineFileSearch } from "react-icons/ai";
import LoadingSpinner from "./components/Spinner";
import "../../assets/styles/inforTable.css";
import { useQuery } from "@apollo/client";
import { InputGroup, Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../../context/AuthContext";
import { GlobalContext } from "../../context/UpdateContext";
import { GET_PRODUCTS, ADD_PRODUCT, UPDATE_PRODUCT, DELETE_PRODUCT } from "../../queries";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";

export default function InforTable() {
  const ITEMS_PER_PAGE = 10;
  const { state, dispatch } = useContext(GlobalContext);
  const navigate = useNavigate();
  const { currentUser, monitorAuthState, logout } = useAuth();

  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [detail, setDetail] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(1);

  const [addProduct,] = useMutation(ADD_PRODUCT);
  const [updateProduct,] = useMutation(UPDATE_PRODUCT);
  const [deleteProduct, ] = useMutation(DELETE_PRODUCT);

  if(currentUser) {
    const expirationDate = new Date(currentUser.stsTokenManager.expirationTime);
    const today = new Date();
    if (expirationDate < today) {
      logout();
      navigate("/login");
    }
  }

  const headers = [
    "Lender",
    "Product",
    "Interest Rate",
    "Comparison Rate",
    "Loan Type",
    "Rate Type",
    "Last Updated"
  ];

  const onDeleteProduct = (id) => {
    setShowDeleteModal(false)
    deleteProduct({variables:{
      productId: id
    }}).then(res => {
      toast.success("Delete product successfully!", {
        position: "top-center",
        autoClose: 2000,
      });
      setTimeout(() => {
        window.location.reload();
      }, "2000");
    }).catch(error => {
      toast.error("Delete product failed!", {
        position: "top-center",
        autoClose: 2000,
      });
    })

  }

  const onUpdateProduct = (formValues) => {
    setShowEditModal(false);
    updateProduct({variables:{
      product: formValues
    }}).then(res => {
      toast.success("Update product successfully!", {
        position: "top-center",
        autoClose: 2000,
      });
      setTimeout(() => {
        window.location.reload();
      }, "2000");
    }).catch(error => {
      toast.error("Update product failed!", {
        position: "top-center",
        autoClose: 2000,
      });
    })
  }

  const onAddProduct = (formValues) => {
    setShowAddModal(false);
    addProduct({variables:{
      product: formValues
    }}).then(res => {
      toast.success("Add product successfully!", {
        position: "top-center",
        autoClose: 2000,
      });
      setTimeout(() => {
        window.location.reload();
      }, "2000");
    }).catch(error => {
      toast.error("Add product failed!", {
        position: "top-center",
        autoClose: 2000,
      });
    })
  }

  const REPAYMENT_TYPE_PI_IO = ["PI & IO", "PI"];

  useEffect(() => {
    refetch({ search: search });
    setCurrentPage(1);
  }, [search]);

  const { loading, error, data, refetch, fetchMore } = useQuery(GET_PRODUCTS, {
    variables: {
      offset: 0,
      limit: ITEMS_PER_PAGE,
      search: search,
    },
    notifyOnNetworkStatusChange: true,
  });

  function DisplayData() {
    if (loading) return <p>Loading...</p>;
    if (error)
      return <div className="error-alert">{`Error! ${error.message}`}</div>;

    const handleMore = (pageNumber) => {
      const nextOffset = (pageNumber - 1) * 10;
      setCurrentPage(pageNumber);
      fetchMore({
        variables: {
          offset: nextOffset,
          limit: ITEMS_PER_PAGE,
        },
      });
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <>{`Error! ${error.message}`}</>;

    const products = {
      list: data?.products?.list.map((product) => {
        let result = {
          ...product,
          Lender: product.lender?.lenderName,
          AncSetupCosts: product.LoanDetails.AncSetupCosts,
          EstFee: product.LoanDetails.EstFee,
          OngoingFeeFreq: product.LoanDetails.OngoingFeeFreq,
          LoanType:
            product.PurposeOO === "true" ? "Owner Occupied" : "Investment",
          RepaymentType: REPAYMENT_TYPE_PI_IO.includes(product.RepaymentType)
            ? "Principal & Interest"
            : "Interest Only",
          FullOffsetAvailable:
            product.LoanDetails.FullOffsetAvailable === "Y" ? "Yes" : "No",
          RedrawAvailable:
            product.LoanDetails.RedrawAvailable === "Y" ? "Yes" : "No",
          MinLoanAmount: new Intl.NumberFormat("en-AU", {
            style: "currency",
            currency: "AUD",
          }).format(product.MinLoanAmount),
          AnnualFee: new Intl.NumberFormat("default", {
            style: "currency",
            currency: "AUD",
          }).format(parseFloat(product.LoanDetails.AnnualFee)),
          OngoingFee: new Intl.NumberFormat("default", {
            style: "currency",
            currency: "AUD",
          }).format(parseFloat(product.LoanDetails.OngoingFee)),
          updatedAt: new Intl.DateTimeFormat("default", {
            year: 'numeric', month: 'numeric', 
            day: 'numeric', hour: 'numeric', 
            minute: 'numeric',  hour12: false,
            timeZone: 'Australia/Sydney'
          }).format(new Date(product.updatedAt))
        };
        Object.keys(result).forEach((key) => {
          if (
            [
              "IntRate",
              "CompareIntRate",
              "LVRRangeMax",
              "LVRRangeMin",
            ].includes(key)
          ) {
            result[key] = new Intl.NumberFormat("en-IN", {
              style: "unit",
              unit: "percent",
            }).format(result[key]);
          }
        });
        return result;
      }),
      total: data?.products?.total,
    };

    return (
      <Container>
        <Table responsive className="table table-striped">
          <thead>
            <tr>
              {headers.map((name) => (
                <th key={name}>{name}</th>
              ))}
              <th key="Action">Action</th>
            </tr>
          </thead>
          <tbody>
            {products.total !== 0 ? (
              products.list.map((r) => (
                <tr
                  key={r["productId"]}
                  justifyContent="center"
                  className="table-row"
                >
                  <td>{r["Lender"]}</td>
                  <td>{r["Product"]}</td>
                  <td>{r["IntRate"]}</td>
                  <td>{r["CompareIntRate"]}</td>
                  <td>{r["LoanType"]}</td>
                  <td>{r["IntType"]}</td>
                  <td>{r["updatedAt"]}</td>
                  <td>
                    <i
                      className="action-icon view-detail-icon"
                      onClick={() => {
                        setDetail(r);
                        setShowModal(true);
                      }} 
                    >
                      <AiOutlineFileSearch />
                    </i>
                    <i
                      className="bi bi-pencil-square action-icon edit-icon"
                      onClick={() => {
                        setDetail(r);
                        setShowEditModal(true);
                      }}
                    ></i>
                    <i
                      onClick={() => {
                        setDetail(r);
                        setShowDeleteModal(true);
                      }}
                      className="bi bi-trash-fill action-icon delete-icon"
                    ></i>
                  </td>
                </tr>
              ))
            ) : (
              <div style={{ fontSize: "20px", marginTop: "3vh" }}>
                <b> No result found</b>
              </div>
            )}
          </tbody>
        </Table>
        <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <div
            className="col-md-8"
            style={{ width: "fit-content", padding: "0", marginRight: "7px" }}
          >
            <CustomPagination
              total={data.products.total}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => handleMore(page)}
            />
          </div>
        </div>
        <DetailModal
          detail={detail}
          show={showModal}
          onHide={() => setShowModal(false)}
        />
        <AddModal
          onHide={() => setShowAddModal(false)}
          show={showAddModal}
          onSubmit={onAddProduct}
        />
        <EditModal
          onHide={() => setShowEditModal(false)}
          show={showEditModal}
          onSubmit={onUpdateProduct}
          prevData={detail}
        />
        <DeleteModal
          onHide={() => setShowDeleteModal(false)}
          show={showDeleteModal}
          onDelete={() => onDeleteProduct(detail["productId"])}
        />
      </Container>
    );
  }

  const updateData = async () => {
    dispatch({
      type: "LOADING_PRODUCT",
      productLoading: "true",
    });
    monitorAuthState();
    const token = currentUser ? currentUser.accessToken : "";
    try {
      const res = await fetch(
        `${process.env.REACT_APP_LOAD_PRODUCT_SERVER_API}/download`,
        {
          method: "get",
          headers: {
            authorization: token ? `Bear ${token}` : "",
          },
        }
      );

      if (res.status === 401) {
        toast.error("Unauthenticated!", {
          position: "top-center",
        });
      } else if (res.status === 200) {
        toast.success("Load products successfully!", {
          position: "top-center",
          autoClose: 2000,
        });
        setTimeout(() => {
          window.location.reload();
        }, "2000");
      } else {
        toast.error("Load products failed. Try again after few minutes", {
          position: "top-center",
        });
      }
    } catch (error) {
      toast.error("Load products failed. Try again after few minutes", {
        position: "top-center",
      });
    }

    dispatch({
      type: "LOADING_PRODUCT",
      productLoading: "false",
    });
  };
  const updateButton = (
    <Button
      variant="primary"
      size="sm"
      style={{ width: "7rem" }}
      onClick={updateData}
    >
      Load Product
    </Button>
  );

  return (
    <Card className="card-container">
      <Card.Header>
        <Container>
          <Row>
            <Col md={8} sm={12} className="search-bar">
              <InputGroup style={{ width: "25rem" }}>
                <Form.Control
                  placeholder="Search by Lender and Product"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </InputGroup>
            </Col>
            <Col md={4} sm={12} className="update-btn">
            <Button
                variant="primary"
                size="sm"
                style={{ width: "7rem", marginRight: "2rem" }}
                onClick={() => setShowAddModal(true)}
              >
                Add Product
              </Button>
              {state.productLoading === "true" ? (
                <LoadingSpinner />
              ) : (
                updateButton
              )}
            </Col>
          </Row>
        </Container>
      </Card.Header>
      <Card.Body>
        <DisplayData />
      </Card.Body>
      <ToastContainer />
    </Card>
  );
}
