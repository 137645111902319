import React from "react";
import { NavLink } from "react-router-dom";
import dashboardAdminRoutes from "../routes";
import "../assets/styles/navbar.css";
import { IconContext } from "react-icons";
import { useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

function Navbar() {

  const {logout} = useAuth();
  const navigate = useNavigate();

  const usePathname = () => {
    const location = useLocation();
    return location.pathname;
  }

  const handleLogout = () => {
    logout();
    navigate('/login')
  }

  const path = usePathname();
  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <nav className="nav-menu active">
          <ul className="nav-menu-items">
            <div>
            {dashboardAdminRoutes.map((item, index) => {
              return (
                <li
                  key={index}
                  className={
                    path === item.path ? "nav-text selected" : "nav-text"
                  }
                >
                  <NavLink to={item.path}>
                    <span>{item.name}</span>
                  </NavLink>
                </li>
              );
            })}
            </div>
            <li className="logout-btn" onClick={handleLogout}>
            <span style={{cursor:"pointer"}}>Log Out</span>
            </li>
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
