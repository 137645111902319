import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";

export default function AffiliateDetailModal(props) {
  const detailField = [
    [
      { name: "Affiliate Name", field: "affiliateName" },
      { name: "Affiliate Id", field: "affiliateId" },
    ],
    [
      { name: "Broker Name", field: "brokerName" },
      { name: "Broker Id", field: "brokerId" },
    ],
    [
      { name: "Company Name", field: "companyName" },
      { name: "ABN", field: "abn" },
    ],
    [
      { name: "Street Address", field: "streetAddress" },
      { name: "Suburb", field: "suburb" },
    ],
    [
      { name: "State", field: "state" },
      { name: "Postal Code", field: "postCode" },
    ],
    [
      { name: "Website", field: "website" },
      { name: "Number of Employees", field: "employeeCount" },
    ],
    [
      { name: "Industry", field: "industry" },
      { name: "Estimated Number of Referrals Per Month", field: "monthlyReferralCount" },
    ],
    [
      { name: "Contact Name", field: "contactName" },
      { name: "Contact Title", field: "contactTitle" },
    ],
    [
      { name: "Contact Phone", field: "contactPhone" },
      { name: "Contact Email", field: "contactEmail" },
    ]
  ];

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      size="xl"
      centered
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ fontSize: "1.8rem", marginLeft: "2.5rem" }}
        >
          Detail
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid" style={{ marginLeft: "2rem" }}>
        <Container>
          {props.detail ? (
            detailField.map((value) => (
              <Row style={{ marginBottom: "0.5rem" }}>
                {value.map((d) => (
                  <Col style={{ fontSize: "1.1rem" }}>
                    <b style={{ paddingLeft: 0, fontSize: "1.2rem" }}>
                      {d.name}:
                    </b>{" "}
                    {props && props.detail ? props.detail[d.field] : null}
                  </Col>
                ))}
              </Row>
            ))
          ) : (
            <Row>No data available</Row>
          )}
          {props.detail && props.detail['status'] === 'active' && (
            <Row style={{ marginBottom: "0.5rem"}}>
              <Col style={{ fontSize: "1.1rem" }}>
                    <b style={{ paddingLeft: 0, fontSize: "1.2rem" }}>
                      Affiliate tracking Url:
                    </b>{" "}
                    <a href={"https://comparensave.com.au/contact?trackingId=" + props.detail['affiliateId']} target="_blank" rel="noreferrer">
                    https://comparensave.com.au/contact?trackingId={props.detail['affiliateId']}
                    </a>
                  </Col>
            </Row>
          )}
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
