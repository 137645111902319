import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";

export default function DetailModal(props) {
  const detailField = [
    [
      { name: "Contact Name", field: "contactName" },
      { name: "Contact Number", field: "contactNumber" },
    ],
    [
      { name: "Email", field: "email" },
      { name: "Surburb", field: "suburb" },
    ],
    [
      { name: "State", field: "state" },
      { name: "Post Code", field: "postCode" },
    ],
    [
      { name: "Comment", field: "comment" },
      { name: "Time Received", field: "createdAt" }
    ],
    [
      { name: "Broker ID", field: "brokerId" },
      { name: "Broker Name", field: "brokerName" }
    ],
    [
      { name: "Affiliate ID", field: "affiliateId" },
      { name: "Affiliate Name", field: "affiliateName" }
    ]
  ]

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      size="xl"
      centered
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ fontSize: "1.8rem", marginLeft: "2.5rem" }}
        >
          Detail
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid" style={{ marginLeft: "2rem" }}>
      <Container>
          {props.detail ? (
            detailField.map((value) => (
              <Row style={{ marginBottom: "0.5rem" }}>
                {value.map((d) => (
                  <Col style={{ fontSize: "1.1rem" }}>
                    <b style={{ paddingLeft: 0, fontSize: "1.2rem" }}>
                      {d.name}:
                    </b>{" "}
                    {
                      (props && props.detail)
                      ? 
                      ((d.name === "Comment" && props.detail[d.field] === "")
                      ? "No comment"
                      : (d.name === "Time Received") ?
                      new Date(props.detail[d.field]).toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'}) :
                      (props.detail[d.field] || "No information")
                      )
                      : 
                      null
                    }
                  </Col>
                ))}
              </Row>
            ))
          ) : (
            <Row>No data available</Row>
          )}
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
