import React from "react";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import "../assets/styles/mainPanel.css";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import Header from "../components/Header";
import { useAuth } from "../context/AuthContext";

export default function AdminLayout() {
  const {currentUser} = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    !currentUser && navigate('/login')
  }, [currentUser])

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div>
        <Header />
      </div>
      <div
      >
        <Navbar className="navbar" />
        <Outlet classname="outlet" />
      </div>
    </div>
  );
}
