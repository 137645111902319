import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

function EditAffiliateModal({prevData, onHide, onSubmit, show}) {
  const data = prevData;
  
  const [affiliateId, setAffiliateId] = useState(data?.affiliateId || "");
  const [affiliateName, setAffiliateName] = useState(data?.affiliateName || "");
  const [brokerId, setBrokerId] = useState(data?.brokerId || "");
  const [brokerName, setBrokerName] = useState(data?.brokerName || "");
  const [companyName, setCompanyName] = useState(data?.companyName || "");
  const [abn, setAbn] = useState(data?.abn || "");
  const [industry, setIndustry] = useState(data?.industry || "");
  const [website, setWebsite] = useState(data?.website || "");
  const [streetAddress, setStreetAddress] = useState(data?.streetAddress || "");
  const [suburb, setSuburb] = useState(data?.suburb || "");
  const [state, setState] = useState(data?.state || "");
  const [postCode, setPostCode] = useState(data?.postCode || "");
  const [employeeCount, setEmployeeCount] = useState(data?.employeeCount || 0);
  const [monthlyReferralCount, setMonthlyReferralCount] = useState(data?.monthlyReferralCount || 0);
  const [contactName, setContactName] = useState(data?.contactName || "");
  const [contactTitle, setContactTitle] = useState(data?.contactTitle || "");
  const [contactPhone, setContactPhone] = useState(data?.contactPhone || "");
  const [contactEmail, setContactEmail] = useState(data?.contactEmail || "");

  const onFormSubmit = (e) => {
    e.preventDefault();
    const formValues = {
      id: data.id,
      affiliateId,
      affiliateName,
      brokerId,
      brokerName,
      companyName,
      abn,
      industry,
      website,
      streetAddress,
      suburb,
      state,
      postCode,
      employeeCount: Number(employeeCount),
      monthlyReferralCount: Number(monthlyReferralCount),
      contactName,
      contactTitle,
      contactPhone,
      contactEmail
    }
    onSubmit(formValues);
  };

  return (
    <Modal
      onHide={onHide}
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      size="xl"
      centered
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ fontSize: "1.8rem" }}
        >
          Edit Affiliate
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Form onSubmit={onFormSubmit}>
          <Form.Group className="mb-2">
            <Form.Label>
              <b>Affiliate Id</b> <b style={{ color: "red" }}>*</b>
            </Form.Label>
            <Form.Control
              required={true}
              type="text"
              placeholder="Enter Affiliate Id"
              value={affiliateId}
              onChange={(e) => setAffiliateId(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label>
              <b>Affiliate Name</b><b style={{ color: "red" }}>*</b>
            </Form.Label>
            <Form.Control
              required={true}
              type="text"
              placeholder="Enter Affiliate Name"
              step={0.01}
              value={affiliateName}
              onChange={(e) => setAffiliateName(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label>
              <b>Broker Id</b> <b style={{ color: "red" }}>*</b>
            </Form.Label>
            <Form.Control
              required={true}
              type="text"
              placeholder="Enter Broker Id"
              value={brokerId}
              onChange={(e) => setBrokerId(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label>
              <b>Broker Name</b><b style={{ color: "red" }}>*</b>
            </Form.Label>
            <Form.Control
              required={true}
              type="text"
              placeholder="Enter Broker Name"
              step={0.01}
              value={brokerName}
              onChange={(e) => setBrokerName(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label>
              <b>Company Name</b><b style={{ color: "red" }}>*</b>
            </Form.Label>
            <Form.Control
              required="true"
              type="text"
              placeholder="Enter Company Name"
              step={0.01}
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label>
              <b>ABN</b>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter ABN"
              step={0.01}
              value={abn}
              onChange={(e) => setAbn(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label><b>Industry</b></Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Industry"
              step={0.01}
              value={industry}
              onChange={(e) => setIndustry(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label><b>Website</b></Form.Label>
            <Form.Control
              type="text"
              placeholder="Website"
              step={0.01}
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label><b>Street Address</b></Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Street Address"
              value={streetAddress}
              onChange={(e) => setStreetAddress(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label><b>Suburb</b></Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Suburb"
              value={suburb}
              onChange={(e) => setSuburb(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label><b>State</b></Form.Label>
            <select
              class="form-select"
              value={state}
              onChange={(e) => setState(e.target.value)}
            >
              <option value="">-- Select a state --</option>
              <option value="NSW">New South Wales</option>
              <option value="VIC">Victoria</option>
              <option value="TAS">Tasmania</option>
              <option value="ACT">Australian Capital Territory</option>
              <option value="WA">Western Australia</option>
              <option value="SA">South Australia</option>
              <option value="QLD">Queensland</option>
            </select>
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label><b>Postal Code</b></Form.Label>
            <Form.Control
              type="test"
              placeholder="Enter Postal Code"
              value={postCode}
              onChange={(e) => setPostCode(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label><b>Number of Employees</b></Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Number of Employees"
              step={0.01}
              value={employeeCount}
              onChange={(e) => setEmployeeCount(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label><b>Estimated Number of Referrals per Month</b></Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Estimated Number of Referrals per Month"
              step={0.01}
              value={monthlyReferralCount}
              onChange={(e) => setMonthlyReferralCount(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label><b>Contact Name</b></Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Contact Name"
              value={contactName}
              onChange={(e) => setContactName(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label><b>Contact Title</b></Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Contact Title"
              value={contactTitle}
              onChange={(e) => setContactTitle(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label><b>Contact Number</b></Form.Label>
            <Form.Control
              type="phone"
              placeholder="Enter Contact Number"
              value={contactPhone}
              onChange={(e) => setContactPhone(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label><b>Contact Email</b></Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter Contact Email"
              value={contactEmail}
              onChange={(e) => setContactEmail(e.target.value)}
            />
          </Form.Group>

          <div className="d-grid gap-2 d-md-flex justify-content-md-end" >
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default EditAffiliateModal;
