import Spinner from 'react-bootstrap/Spinner';
import { Button } from "react-bootstrap";

function LoadingSpinner() {
    
  return (
    <Button
    variant="primary"
    size="sm"
    disabled
  >
        <Spinner animation="border" size="sm" /> 
        {" "}
        Please wait for few minutes
  </Button>
  )
}

export default LoadingSpinner;