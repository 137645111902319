import React, { useEffect, useState, useMemo } from "react";
import Pagination from "react-bootstrap/Pagination";
import '../../../assets/styles/inforTable.css'

export default function CustomPagination({
  total = 0,
  itemsPerPage = 10,
  currentPage = 1,
  siblingCount = 1,
  onPageChange,
}) {
  const [totalPages, setTotalPages] = useState(0);
  useEffect(() => {
    if (total > 0 && itemsPerPage > 0)
      setTotalPages(Math.ceil(total / itemsPerPage));
  }, [total, itemsPerPage]);

  const paginationItems = useMemo(() => {
    const pages = [];
    let paginationRange = [];
    if(currentPage > 3 && currentPage < totalPages-1) {
      for (let i = 0; i < 5; i++) {
        if (currentPage + i - 2 > 0)
          paginationRange.push(currentPage + i - 2);
      }
    }
    else if( currentPage === totalPages - 1) {
      for (let i = 0; i < 5; i++) {
        if (currentPage + i - 3 > 0)
          paginationRange.push(currentPage + i - 3);
      }
    
    }
    else if (currentPage === totalPages) {
      for (let i = 0; i < 5; i++) {
        if (currentPage + i - 4 > 0)
          paginationRange.push(currentPage + i - 4);
      }
    } else {
      paginationRange = [1, 2, 3, 4, 5]
    }
    paginationRange.map((pageNumber) => {
      pages.push(
        <Pagination.Item
          key={pageNumber}
          active={pageNumber === currentPage}
          onClick={() => onPageChange(pageNumber)}
          style={{cursor:"pointer"}}
        >
          {pageNumber}
        </Pagination.Item>
      );
      return pages;
    });

    return pages;
  }, [totalPages, currentPage, onPageChange, siblingCount]);

  if (totalPages === 0) return null;
  return (
    
    <Pagination size="sm">
      <li className={currentPage ===1 ? 'page-item disable' :'page-item'} onClick={() => currentPage !== 1  && onPageChange(currentPage = 1)}>
      <a className="page-link" aria-label="Previous" style={{ padding: "0.25rem 0"}}>
        <span style={{margin: "0 0.5rem", cursor:"pointer"}} aria-hidden="true">&laquo;</span>
      </a>
      </li>
      <li className={currentPage ===1 ? 'page-item disable' :'page-item'} onClick={() => currentPage !== 1  && onPageChange(currentPage - 1)}>
      <a className="page-link" aria-label="Previous" style={{ padding: "0.25rem 0"}}>
        <span style={{margin: "0 0.5rem", fontSize:"0.7rem", cursor:"pointer"}} aria-hidden="true">&lt;</span>
      </a>
      </li>
      {paginationItems}
      <li className={currentPage === totalPages ? 'page-item disable' :'page-item'} onClick={() => currentPage !== totalPages  && onPageChange(currentPage + 1)}>
      <a className="page-link" aria-label="Previous" style={{ padding: "0.25rem 0"}}>
        <span style={{margin: "0 0.5rem",  fontSize:"0.7rem", cursor:"pointer"}} aria-hidden="true">&gt;</span>
      </a>
      </li>
      <li className={currentPage === totalPages ? 'page-item disable' :'page-item'} onClick={() => currentPage !== totalPages  && onPageChange(currentPage = totalPages)}>
      <a className="page-link" aria-label="Previous" style={{ padding: "0.25rem 0"}}>
        <span style={{margin: "0 0.5rem", cursor:"pointer"}} aria-hidden="true">&raquo;</span>
      </a>
      </li>
    </Pagination>
  );
}
