import React, { useState } from "react";
import CustomPagination from "./components/Pagination";
import { Container, Table, Card, Col } from "react-bootstrap";
import { AiOutlineFileSearch } from "react-icons/ai";
import "../../assets/styles/inforTable.css";
import { useQuery, useMutation } from "@apollo/client";
import { InputGroup, Form } from "react-bootstrap";
import { GET_LEADS, DELETE_LEAD } from "../../queries";
import LeadModal from "./components/LeadModal";
import DeleteModal from "./components/DeleteModal";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function LeadTable() {
  const ITEMS_PER_PAGE = 10;
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const [variables, setVariables] = useState({
    limit: ITEMS_PER_PAGE,
    offset: 0,
    search: ""
  });
  const [showModal, setShowModal] = useState(false);
  const [detail, setDetail] = useState(undefined);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [deleteLead, ] = useMutation(DELETE_LEAD);

  if(currentUser) {
    const expirationDate = new Date(currentUser.stsTokenManager.expirationTime);
    const today = new Date()
    if( expirationDate < today ) {
      logout();
      navigate('/login');
    }
  }  
  
  const headers = [
    "Affiliate Name",
    "Broker Name",
    "Contact Name",
    "Contact Number",
    "Email",
    "Suburb",
    "State",
    "Post Code",
    "Time Received",
    "Action"
  ];

  const onDeleteLead = (id) => {
    setShowDeleteModal(false)
    deleteLead({variables:{
      leadId: id
    }}).then(res => {
      toast.success("Delete lead successfully!", {
        position: "top-center",
        autoClose: 2000,
      });
      setTimeout(() => {
        window.location.reload();
      }, "2000");
    }).catch(error => {
      toast.error("Delete lead failed!", {
        position: "top-center",
        autoClose: 2000,
      });
    })

  }

  const { loading, error, data } = useQuery(GET_LEADS, {
    variables: variables,
    notifyOnNetworkStatusChange: true,
  });

  function DisplayData() {
    if (loading) return <p>Loading...</p>;
    if (error) return <div className="error-alert">{`Error! ${error.message}`}</div>;

    const handleMore = async (pageNumber) => {
      const nextOffset = (pageNumber - 1) * 10;
      setVariables({
        ...variables,
        offset: nextOffset
      })
      setCurrentPage(pageNumber);
    };

    return (
      <Container>
        <Table responsive className="table table-striped">
          <thead>
            <tr align="center">
              {headers.map((name) => (
                <th key={name}>{name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.leads.total !== 0 ? (
              data?.leads.list.map((r) => (
                <tr align="center" key={r["leadId"]}>
                  <td>{r["affiliateName"]}</td>
                  <td>{r["brokerName"]}</td>
                  <td>{r["contactName"]}</td>
                  <td>{r["contactNumber"]}</td>
                  <td>{r["email"]}</td>
                  <td>{r["suburb"]}</td>
                  <td>{r["state"]}</td>
                  <td>{r["postCode"]}</td>
                  <td>{new Date(r["createdAt"]).toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'})}</td>
                  <td style={{ verticalAlign: "middle" }}>
                    <i
                      className="action-icon view-detail-icon"
                      onClick={() => {
                        setDetail(r);
                        setShowModal(true);
                      }} 
                    >
                      <AiOutlineFileSearch />
                    </i>
                    <i
                      onClick={() => {
                        setDetail(r);
                        setShowDeleteModal(true);
                      }}
                      className="bi bi-trash-fill action-icon delete-icon"
                    ></i>
                  </td>
                </tr>
              ))
            ) : (
              <div style={{ fontSize: "20px", marginTop: "3vh" }}>
                <b> No result found</b>
              </div>
            )}
          </tbody>
        </Table>
        <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <div
            className="col-md-8"
            style={{ width: "fit-content", padding: "0", marginRight: "7px" }}
          >
            <CustomPagination
              total={data.leads.total}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => handleMore(page)}
            />
          </div>
        </div>
        <LeadModal
          detail={detail}
          show={showModal}
          onHide={() => setShowModal(false)}
        />
        <DeleteModal
          onHide={() => setShowDeleteModal(false)}
          show={showDeleteModal}
          onDelete={() => onDeleteLead(detail["leadId"])}
        />
      </Container>
    );
  }

  return (
    <Card className="card-container">
      <Card.Header>
        <Container>
          <Col md={8} sm={12} className="search-bar">
            <InputGroup style={{ width: "25rem" }}>
              <Form.Control
                placeholder="Search by Contact name and Email"
                onChange={(e) => {
                  setVariables({
                    ...variables,
                    search: e.target.value,
                    offset: 0 
                  })
                  setCurrentPage(1);
                }}
              />
            </InputGroup>
          </Col>
        </Container>
      </Card.Header>
      <Card.Body>
        <DisplayData />
      </Card.Body>
      <ToastContainer />
    </Card>
  );
}
