import React, { useState } from "react";
import { AiOutlineFileSearch } from "react-icons/ai";
import CustomPagination from "./components/Pagination";
import { Container, Table, Card, Col, Button, Row } from "react-bootstrap";
import "../../assets/styles/inforTable.css";
import { useMutation, useQuery } from "@apollo/client";
import { InputGroup, Form } from "react-bootstrap";
import { GET_AFFILIATES, UPDATE_AFFILIATE, ADD_AFFILIATE } from "../../queries";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AffiliateDetailModal from "./components/AffiliateDetailModal";
import EditAffiliateModal from "./components/EditAffiliateModal";
import AddAffiliateModal from "./components/AddAffiliateModal";
import ApproveAffiliateModal from "./components/ApproveAffiliateModal";

export default function AffiliateTable() {
  const ITEMS_PER_PAGE = 10;
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const [variables, setVariables] = useState({
    limit: ITEMS_PER_PAGE,
    offset: 0,
    search: ""
  });
  const [detail, setDetail] = useState(undefined);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [addAffiliate,] = useMutation(ADD_AFFILIATE);
  const [updateAffiliate,] = useMutation(UPDATE_AFFILIATE);

  if(currentUser) {
    const expirationDate = new Date(currentUser.stsTokenManager.expirationTime);
    const today = new Date()
    if( expirationDate < today ) {
      logout();
      navigate('/login');
    }
  }  
  
  const headers = [
    "Company Name",
    "Affiliate Name",
    "Broker Name",
    "Status",
    "Action"
  ];

  const onUpdateAffiliate = (formValues) => {
    setShowEditModal(false);
    updateAffiliate({variables:{
      affiliate: formValues
    }}).then(res => {
      toast.success("Update affiliate successfully!", {
        position: "top-center",
        autoClose: 2000,
      });
      setTimeout(() => {
        window.location.reload();
      }, "2000");
    }).catch(error => {
      console.log("error", error);
      toast.error("Update affiliate failed!", {
        position: "top-center",
        autoClose: 2000,
      });
    })
  };

  const onAddAffiliate = (formValues) => {
    setShowAddModal(false);
    addAffiliate({variables:{
      affiliate: formValues
    }}).then(res => {
      toast.success("Add affiliate successfully!", {
        position: "top-center",
        autoClose: 2000,
      });
      setTimeout(() => {
        window.location.reload();
      }, "2000");
    }).catch(error => {
      toast.error("Add affiliate failed!", {
        position: "top-center",
        autoClose: 2000,
      });
    })
  }

  const onApproveAffiliate = (id) => {
    setShowApproveModal(false)
    updateAffiliate({variables:{
      affiliate: {
        id: id,
        status: 'active'
      }
    }}).then(res => {
      toast.success("Approve affiliate successfully!", {
        position: "top-center",
        autoClose: 2000,
      });
      setTimeout(() => {
        window.location.reload();
      }, "2000");
    }).catch(error => {
      toast.error("Approve affiliate failed!", {
        position: "top-center",
        autoClose: 2000,
      });
    })

  }

  const { loading, error, data } = useQuery(GET_AFFILIATES, {
    variables: variables,
    notifyOnNetworkStatusChange: true,
  });

  function DisplayData() {
    if (loading) return <p>Loading...</p>;
    if (error) return <div className="error-alert">{`Error! ${error.message}`}</div>;

    const handleMore = async (pageNumber) => {
      const nextOffset = (pageNumber - 1) * 10;
      setVariables({
        ...variables,
        offset: nextOffset
      })
      setCurrentPage(pageNumber);
    };

    return (
      <Container>
        <Table responsive className="table table-striped">
          <thead>
            <tr align="center">
              {headers.map((name) => (
                <th key={name}>{name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.getAffiliates.total !== 0 ? (
              data?.getAffiliates.list.map((r) => (
                <tr align="center" key={r["affiliateId"]}>
                  <td>{r["companyName"]}</td>
                  <td>{r["affiliateName"]}</td>
                  <td>{r["brokerName"]}</td>
                  <td>{r["status"]}</td>
                  <td>
                  <i
                      className="action-icon view-detail-icon"
                      onClick={() => {
                        setDetail(r);
                        setShowModal(true);
                      }} 
                    >
                      <AiOutlineFileSearch />
                    </i>
                    <i
                      className="bi bi-pencil-square action-icon edit-icon"
                      onClick={() => {
                        setDetail(r);
                        setShowEditModal(true);
                      }}
                    ></i>
                    {r['status'] !== "active" && (
                      <i
                        onClick={() => {
                          setDetail(r);
                          setShowApproveModal(true);
                        }}
                        className="bi bi-check-square action-icon check-icon"
                      ></i>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <div style={{ fontSize: "20px", marginTop: "3vh" }}>
                <b>No result found</b>
              </div>
            )}
          </tbody>
        </Table>
        <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <div
            className="col-md-8"
            style={{ width: "fit-content", padding: "0", marginRight: "7px" }}
          >
            <CustomPagination
              total={data.getAffiliates.total}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => handleMore(page)}
            />
          </div>
        </div>
        <AffiliateDetailModal
          detail={detail}
          show={showModal}
          onHide={() => setShowModal(false)}
        />
        <AddAffiliateModal
          onHide={() => setShowAddModal(false)}
          show={showAddModal}
          onSubmit={onAddAffiliate}
        />
        <EditAffiliateModal
          onHide={() => setShowEditModal(false)}
          show={showEditModal}
          onSubmit={onUpdateAffiliate}
          prevData={detail}
        />
        <ApproveAffiliateModal
          onHide={() => setShowApproveModal(false)}
          show={showApproveModal}
          onSubmit={() => onApproveAffiliate(detail["affiliateId"])}
        />
      </Container>
    );
  }

  return (
    <Card className="card-container">
      <Card.Header>
        <Container>
          <Row>
            <Col md={8} sm={12} className="search-bar">
              <InputGroup style={{ width: "25rem" }}>
                <Form.Control
                  placeholder="Search by affiliate, broker and company name"
                  onChange={(e) => {
                    setVariables({
                      ...variables,
                      search: e.target.value,
                      offset: 0 
                    })
                    setCurrentPage(1);
                  }}
                />
              </InputGroup>
            </Col>
            <Col md={4} sm={12} className="update-btn">
              <Button
                  variant="primary"
                  size="sm"
                  style={{ width: "7rem", marginRight: "2rem" }}
                  onClick={() => setShowAddModal(true)}
                >
                  Add Affiliate
              </Button>
            </Col>
          </Row>  
        </Container>
      </Card.Header>
      <Card.Body>
        <DisplayData />
      </Card.Body>
      <ToastContainer />
    </Card>
  );
}
