import React from "react";
import { useRef, useState, useEffect } from "react";
import { Form, Button, Card, Alert } from "react-bootstrap";
import { useAuth } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import "../../../assets/styles/login.css";

export default function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const [error, setError] = useState("");
  const { currentUser, login } = useAuth();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  //if already log in
  useEffect(() => {
    if (currentUser) navigate("/");
  });
  //

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    const response = await login(
      emailRef.current.value,
      passwordRef.current.value
    );
    setLoading(false);
    if (response.includes("@")) {
      navigate("/");
    }
    setError("Wrong email or password");
  };

  return (
    <>
      <Card className="form-container">
        <Card.Body>
          <h2 className="text-center mb-4">Log in</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form>
            <Form.Group className="form-group" id="email">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} required />
            </Form.Group>
            <Form.Group className="form-group" id="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                ref={passwordRef}
                required
              ></Form.Control>
            </Form.Group>
            <Button
              disabled={loading}
              className="w-100"
              onClick={(e) => handleSubmit(e)}
            >
              Login
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
}
