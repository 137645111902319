import React from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";

export default function ApproveAffiliateModal({onSubmit, show, onHide}) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      size="md"
      centered
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ fontSize: "1.5rem",marginLeft: "1rem" }}
        >
          Approve Affiliate
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid" style={{ marginLeft: "2rem" }}>
        <Row style={{ fontSize: "1.2rem" }}>Are you sure you want to approve this affiliate?</Row>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" class="btn btn-secondary" onClick={onHide}>Close</button>
        <button type="button" class="btn btn-primary" onClick={onSubmit}>Approve</button> 
      </Modal.Footer>
    </Modal>
  );
}
