import { gql } from '@apollo/client';

const GET_PRODUCTS = gql`
  query ($offset: Int, $limit: Int, $search: String) {
    products(offset: $offset, limit: $limit, search: $search) {
      list {
        productId
        IntRate
        CompareIntRate
        FixedRateTerm
        PurposeOO
        PurposeINV
        RepaymentType
        IntType
        MinLoanAmount
        LVRRangeMin
        LVRRangeMax
        lender {
          lenderName
        }
        Description
        LoanDetails
        Product
        createdAt
        updatedAt
      }
      total
    }
  }
`;

const GET_LEADS = gql`
  query Query($offset: Int, $limit: Int, $search: String) {
    leads(offset: $offset, limit: $limit, search: $search) {
      list {
        leadId
        contactName
        postCode
        state
        suburb
        email
        contactNumber
        comment
        affiliateId
        brokerId
        brokerName
        affiliateName
        createdAt
        updatedAt
      }
      total
    }
  }
`;

const ADD_PRODUCT = gql`
mutation Mutation($product: CreateProduct!) {
  addProduct(product: $product) {
    CompareIntRate
    Description
    FixedRateTerm
    IntRate
    IntType
    LVRRangeMax
    LVRRangeMin
    LoanDetails
    MinLoanAmount
    Product
    PurposeINV
    PurposeOO
    RepaymentType
    createManually
    StartDate
    ProductEnabled
    HideDetails
    EndDate
    LenderEnabled
    productId
    lender {
      lenderId
      lenderName
      logo
    }
    createdAt
    updatedAt
  }
}
`;

const UPDATE_PRODUCT = gql`
mutation Mutation($product: UpdateProduct!) {
  updateProduct(product: $product) {
    productId
    IntRate
    CompareIntRate
    FixedRateTerm
    PurposeOO
    PurposeINV
    RepaymentType
    IntType
    MinLoanAmount
    LVRRangeMin
    LVRRangeMax
    StartDate
    EndDate
    LenderEnabled
    ProductEnabled
    HideDetails
    lender {
      logo
      lenderName
      lenderId
    }
    Description
    LoanDetails
    Product
    createdAt
    updatedAt
  }
}
`;

const DELETE_PRODUCT = gql`
mutation Mutation($productId: String) {
  deleteProduct(productId: $productId)
}`;

const DELETE_LEAD = gql`
mutation Mutation($leadId: String) {
  deleteLead(leadId: $leadId)
}`;

const GET_AFFILIATES = gql`
  query Query($offset: Int, $limit: Int, $search: String) {
    getAffiliates(offset: $offset, limit: $limit, search: $search) {
      list {
        id
        affiliateId
        affiliateName
        brokerId
        brokerName
        companyName
        abn
        industry
        streetAddress
        suburb
        state
        postCode
        website
        employeeCount
        monthlyReferralCount
        contactName
        contactTitle
        contactPhone
        contactEmail
        status
      }
      total
    }
  }
`;

const UPDATE_AFFILIATE = gql`
mutation Mutation($affiliate: UpdateAffiliate!) {
  updateAffiliate(affiliate: $affiliate) {
    affiliateId
    affiliateName
    brokerId
    brokerName
    companyName
    abn
    industry
    streetAddress
    suburb
    state
    postCode
    website
    employeeCount
    monthlyReferralCount
    contactName
    contactTitle
    contactPhone
    contactEmail
    status
  }
}
`;

const ADD_AFFILIATE = gql`
mutation Mutation($affiliate: CreateAffiliate!) {
  addAffiliate(affiliate: $affiliate) {
    id
    affiliateId
    affiliateName
    brokerId
    brokerName
    companyName
    abn
    industry
    streetAddress
    suburb
    state
    postCode
    website
    employeeCount
    monthlyReferralCount
    contactName
    contactTitle
    contactPhone
    contactEmail
    status
  }
}
`;

export { 
  GET_PRODUCTS, GET_LEADS, 
  ADD_PRODUCT, UPDATE_PRODUCT, 
  DELETE_PRODUCT, DELETE_LEAD, 
  GET_AFFILIATES, UPDATE_AFFILIATE, ADD_AFFILIATE 
}