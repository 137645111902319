import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";

export default function DetailModal(props) {
  const detailField = [
    [
      { name: "Lender", field: "Lender" },
      { name: "Interest Rate", field: "IntRate" },
    ],
    [
      { name: "Product", field: "Product" },
      { name: "Comparison Rate", field: "CompareIntRate" },
    ],
    [
      { name: "Description", field: "Description" },
      { name: "Redraw", field: "RedrawAvailable" },
    ],
    [
      { name: "Loan Type", field: "LoanType" },
      { name: "Offset Account", field: "FullOffsetAvailable" },
    ],
    [
      { name: "Rate Type", field: "IntType" },
      { name: "Application Fee", field: "EstFee" },
    ],
    [
      { name: "Repayment Type", field: "RepaymentType" },
      { name: "Ancilliary Setup Fee", field: "AncSetupCosts" },
    ],
    [
      { name: "Minimum Loan Amount", field: "MinLoanAmount" },
      { name: "Annual Fee", field: "AnnualFee" },
    ],
    [
      { name: "Minimum Loan To Value Ratio", field: "LVRRangeMin" },
      { name: "Monthly Fee", field: "OngoingFee" },
    ],
    [
      { name: "Maximum Loan To Value Ratio", field: "LVRRangeMax" },
      { name: "Ongoing Fee Frequency", field: "OngoingFeeFreq" },
    ],
    [
      { name: "Fixed Rate Term", field: "FixedRateTerm" },
      { name: "Last Updated", field: "updatedAt" }
    ],
  ];

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      size="xl"
      centered
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ fontSize: "1.8rem", marginLeft: "2.5rem" }}
        >
          Detail
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid" style={{ marginLeft: "2rem" }}>
        <Container>
          {props.detail ? (
            detailField.map((value) => (
              <Row style={{ marginBottom: "0.5rem" }}>
                {value.map((d) => (
                  <Col style={{ fontSize: "1.1rem" }}>
                    <b style={{ paddingLeft: 0, fontSize: "1.2rem" }}>
                      {d.name}:
                    </b>{" "}
                    {props && props.detail ? props.detail[d.field] : null}
                  </Col>
                ))}
              </Row>
            ))
          ) : (
            <Row>No data available</Row>
          )}
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
