const dashboardAdminRoutes = [
  {
    path: "/",
    name: "Products",
    icon: "nc-icon nc-notes",
  },
  {
    path: "/leads",
    name: "Leads",
    icon: "nc-icon nc-notes",
  },
  {
    path: "/affiliates",
    name: "Affiliates",
    icon: "nc-icon nc-notes",
  },
];

export default dashboardAdminRoutes;
