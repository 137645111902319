import React, { createContext } from "react";
import { useReducer } from "react";

const initialState = {
    productLoading: "false",
};

const GlobalContext = createContext(initialState)
const { Provider } = GlobalContext

const UpdateProvider = ({ children }) => {
    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case "LOADING_PRODUCT":
                return Object.assign({}, state, { 
                    productLoading: action.productLoading,
                })
            default:
                return state

        }
    }, initialState)

    return <Provider value={{ state, dispatch }}>{children}</Provider>

}

export { GlobalContext, UpdateProvider }
