import { AuthProvider } from "./context/AuthContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./views/pages/common/Login";
import AdminLayout from "./views/AdminLayout";
import InforTable from "./views/pages/InforTable";
import LeadTable from "./views/pages/LeadTable";
import AffiliateTable from "./views/pages/AffiliateTable";
import { useAuth } from "./context/AuthContext";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { UpdateProvider } from "./context/UpdateContext";

export default function App() {
  return (
    <AuthProvider>
      <UpdateProvider>
        <AdminPortal />
      </UpdateProvider>
    </AuthProvider>
  );
}

const AdminPortal = () => {
  const { currentUser, monitorAuthState } = useAuth();
  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_GRAPHQL_SERVER_API}`,
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    monitorAuthState();
    const token = currentUser ? currentUser.accessToken : "";
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bear ${token}` : "",
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            products: {
              //Query name
              keyArgs: [],
            },
          },
        },
      },
    }),
  });

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<AdminLayout />}>
            <Route path="" element={<InforTable />} />
            <Route path="leads" element={<LeadTable />} />
            <Route path="affiliates" element={<AffiliateTable />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ApolloProvider>
  );
};
