import "../assets/styles/header.css";
import logo from '../assets/img/comparensave_logo.png'
import { Image } from "react-bootstrap";
import { NavLink } from "react-router-dom";

function Header() {
    return (
        <div className="header">
            <NavLink to="/">
            <Image className="header-logo" alt="CompareNSave logo" src={logo} />
            </NavLink>
        </div>
      );
}

export default Header