import React from "react";
import { useContext, useEffect, useState } from "react";
import { auth } from "../firebase";
import {
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
  onAuthStateChanged,
  getIdToken
} from "firebase/auth";

const AuthContext = React.createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loadingPreviousState, setLoadingPreviousState] = useState(false);

  const monitorAuthState = () => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        if (user.stsTokenManager.isExpired) {
          const accessToken = await getIdToken(user, true);
          user.accessToken = accessToken;
        }
        setLoadingPreviousState(true);
        setCurrentUser(user);
      }
      setLoadingPreviousState(true);
    });
  };

  useEffect(() => {
    monitorAuthState();
  }, []);
  //

  const login = async (email, password) => {
    const signIn = await signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        setCurrentUser(user);
        return user.email;
        // ...
      })
      .catch((error) => {
        return error.code;
      });
    return signIn;
  };

  const logout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        setCurrentUser(null);
        console.log("User sign out");
      })
      .catch((error) => {
        // An error happened.
      });
  };

  const updateAccountInfo = async (newName, newPassword) => {
    let result;
    await updateProfile(auth.currentUser, {
      displayName: newName,
    })
      .then(() => {
        // Profile updated!
        // ...
        result = "ok";
      })
      .catch((error) => {
        result = error.message;
        // An error occurred
        // ...
      });
    return result;
  };

  const value = {
    currentUser,
    login,
    logout,
    updateAccountInfo,
    monitorAuthState
  };

  return (
    loadingPreviousState && (
      <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    )
  );
}
