import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

function EditModal({prevData, onHide, onSubmit, show}) {
  const data = prevData;
  const EstFee = data?.LoanDetails?.EstFee?.split('$').join("")
  const AncSetupCosts = data?.LoanDetails?.AncSetupCosts?.split('$').join("")
  if(data) {
    Object.keys(data).forEach(function(key, index) {
        if (typeof data[key] === "string"){
            data[key] = data[key].split('$').join("")
            data[key] = data[key].split('%').join("")
            data[key] = data[key].split(',').join("")
        }
    })
  }

  const [lender, setLender] = useState(data?.Lender || "");
  const [product, setProduct] = useState(data?.Product || "");
  const [interestRate, setInterestRate] = useState(data?.IntRate || 0);
  const [compareInterestRate, setCompareInterestRate] = useState(data?.CompareIntRate || 0);
  const [description, setDescription] = useState(data?.Description || "");
  const [redraw, setRedraw] = useState(data?.LoanDetails.RedrawAvailable === "Y" ? true : false);
  const [loanType, setLoanType] = useState(data?.LoanType || "Owner Occupied");
  const [offsetAccount, setOffsetAccount] = useState(data?.LoanDetails.FullOffsetAvailable === "Y" ? true : false);
  const [rateType, setRateType] = useState(data?.IntType || "FIXED");
  const [estimateFee, setEstimateFee] = useState(EstFee || 0);
  const [repaymentType, setRepaymentType] = useState(data?.RepaymentType || "Principal & Interest");
  const [ancilliarySetupFee, setAncilliarySetupFee] = useState(AncSetupCosts || 0);
  const [minimumLoanAmount, setMinimumLoanAmount] = useState(data?.MinLoanAmount || 0);
  const [annualFee, setAnnualFee] = useState(data?.LoanDetails.AnnualFee || 0);
  const [minimumLoanToValueRatio, setMinimumLoanToValueRatio] = useState(data?.LVRRangeMin || 0);
  const [ongoingFee, setOnGoingFee] = useState(data?.LoanDetails.OngoingFee || 0);
  const [maximumLoanToValueRatio, setMaximumLoanToValueRatio] = useState(data?.LVRRangeMax || 0);
  const [ongoingFeeFrequency, setOngoingFeeFrequency] = useState(data?.LoanDetails.OngoingFeeFreq || "Annually");
  const [fixedRateTerm, setFixedRateTerm] = useState(data?.FixedRateTerm || 0);

  const onFormSubmit = (e) => {
    e.preventDefault();
    const formValues = {
      CompareIntRate: Number(compareInterestRate),
      Description: description,
      FixedRateTerm: Number(fixedRateTerm),
      IntRate: Number(interestRate),
      IntType: rateType,
      LVRRangeMax: Number(maximumLoanToValueRatio),
      LVRRangeMin: Number(minimumLoanToValueRatio),
      LoanDetails: {
        AncSetupCosts: ancilliarySetupFee.includes("$") ? ancilliarySetupFee : "$" + Number(ancilliarySetupFee),
        AnnualFee: Number(annualFee),
        EstFee: estimateFee.includes("$") ? estimateFee : "$" + Number(estimateFee),
        FullOffsetAvailable: offsetAccount ? "Y" : "N",
        OngoingFee: Number(ongoingFee),
        OngoingFeeFreq: ongoingFeeFrequency,
        RedrawAvailable: redraw ? "Y" : "N",
      },
      MinLoanAmount: Number(minimumLoanAmount),
      Product: product,
      PurposeINV: loanType === "Investment" ? "true" : "false",
      PurposeOO: loanType === "Owner Occupied" ? "true" : "false",
      RepaymentType: repaymentType,
      lender: {
        lenderName: lender,
      },
      productId: data.productId
    }
    onSubmit(formValues);
    
  };

  return (
    <Modal
      onHide={onHide}
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      size="xl"
      centered
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ fontSize: "1.8rem" }}
        >
          Edit Product
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Form onSubmit={onFormSubmit}>
          <Form.Group className="mb-2">
            <Form.Label>
              <b>Lender</b> <b style={{ color: "red" }}>*</b>
            </Form.Label>
            <Form.Control
              required="true"
              type="text"
              placeholder="Enter Lender"
              value={lender}
              onChange={(e) => setLender(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label>
              <b>Interest Rate (%)</b>
            </Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Interest Rate"
              step={0.01}
              value={interestRate}
              onChange={(e) => setInterestRate(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label>
              <b>Product</b> <b style={{ color: "red" }}>*</b>
            </Form.Label>
            <Form.Control
              required="true"
              type="text"
              placeholder="Enter Product"
              value={product}
              onChange={(e) => setProduct(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label>
              <b>Comparison Rate (%)</b>
            </Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Comparison Rate"
              step={0.01}
              value={compareInterestRate}
              onChange={(e) => setCompareInterestRate(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label>
              <b>Description</b>
            </Form.Label>
            <textarea
              class="form-control"
              rows="5"
              placeholder="Enter Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
          </Form.Group>

          <Form.Group className="mb-2">
            <label class="form-check-label">
              <b>Redraw</b>
            </label>{" "}
            <input
              style={{ marginLeft: "1.5rem" }}
              class="form-check-input"
              type="checkbox"
              value={redraw}
              onChange={(e) => setRedraw(e.target.checked)}
              checked={redraw}
            />
            <label class="form-check-label" style={{ marginLeft: "3rem" }}>
              <b>Offset Account</b>
            </label>{" "}
            <input
              style={{ marginLeft: "1.5rem" }}
              class="form-check-input"
              type="checkbox"
              value={offsetAccount}
              onChange={(e) => setOffsetAccount(e.target.checked)}
              checked={offsetAccount}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label><b>Loan Type</b></Form.Label>
            <select
              class="form-select"
              value={loanType}
              onChange={(e) => setLoanType(e.target.value)}
              required={true}
            >
              <option value="Owner Occupied">Owner Occupied</option>
              <option value="Investment">Investment</option>
            </select>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label><b>Rate Type</b></Form.Label>
            <select
              class="form-select"
              value={rateType}
              onChange={(e) => setRateType(e.target.value)}
            >
              <option value="FIXED">FIXED</option>
              <option value="VARIABLE">VARIABLE</option>
            </select>
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label><b>Application Fee ($)</b></Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Application Fee"
              value={estimateFee}
              onChange={(e) => setEstimateFee(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label><b>Repayment Type</b></Form.Label>
            <select
              class="form-select"
              required="true"
              value={repaymentType}
              onChange={(e) => setRepaymentType(e.target.value)}
            >
              <option value="Principal & Interest"><b>Principal & Interest</b></option>
              <option value="Interest Only"><b>Interest Only</b></option>
            </select>
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label><b>Ancilliary Setup Fee ($)</b></Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Ancilliary Setup Fee"
              value={ancilliarySetupFee}
              onChange={(e) => setAncilliarySetupFee(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label><b>Minimum Loan Amount ($)</b></Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Minimum Loan Amount"
              value={minimumLoanAmount}
              onChange={(e) => setMinimumLoanAmount(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label><b>Minimum Loan To Value Ratio (%)</b></Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Minimum Loan To Value Ratio"
              step={0.01}
              value={minimumLoanToValueRatio}
              onChange={(e) => setMinimumLoanToValueRatio(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label><b>Maximum Loan To Value Ratio (%)</b></Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Maximum Loan To Value Ratio"
              step={0.01}
              value={maximumLoanToValueRatio}
              onChange={(e) => setMaximumLoanToValueRatio(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label><b>Annual Fee ($)</b></Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Annual Fee"
              value={annualFee}
              onChange={(e) => setAnnualFee(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label><b>Monthly Fee ($)</b></Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Monthly Fee"
              value={ongoingFee}
              onChange={(e) => setOnGoingFee(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label><b>Ongoing Fee Frequency</b></Form.Label>
            <select
              class="form-select"
              value={ongoingFeeFrequency}
              onChange={(e) => setOngoingFeeFrequency(e.target.value)}
            >
              <option value="Annually">Annually</option>
              <option value="Monthly">Monthly</option>
            </select>
          </Form.Group>

          <Form.Group className="mb-2">
            <Form.Label><b>Fixed Rate Term (years)  </b></Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter Fixed Rate Term"
              value={fixedRateTerm}
              onChange={(e) => setFixedRateTerm(e.target.value)}
            />
          </Form.Group>

          <div className="d-grid gap-2 d-md-flex justify-content-md-end" >
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default EditModal;
